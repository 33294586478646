.language-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 14px;
    cursor: pointer;
    width: 20px;
}

.arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: #555;
    cursor: pointer;
}

.fa-magnifying-glass {
    color: #FFFFFF;
    font-size: 1.5rem;
    cursor: pointer;
}

.select-wrapper select {
    appearance: none;
    color: #555;
    transition: 0.3s ease;
}

.select-wrapper select:focus,
.select-wrapper select:hover {
    background-color: #dedddd;
    border-color: #555;
}