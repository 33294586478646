body {
  background-color: rgb(255, 255, 255);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 0;
}

.imgLogo {
  width: 200px;
  height: 200px;
}

.paragraph2 {
  max-width: 992px;
}

.hero-paragraph::after {
  content: "";
  width: 1px;
  height: 70%;
  background-color: rgb(149, 7, 7);
  position: absolute;
  left: -3rem;
  top: 13%;
}

.frame {
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(135deg, #fc0000 180px, #ffffff 0) border-box;
  background-repeat: no-repeat;
  border-radius: 25px;
  border: 2px solid transparent;
}

.r-frame {
  background: linear-gradient(#ffffff, #ffffff) padding-box,
    linear-gradient(-45deg, #fc0000 180px, #ffffff 0) border-box;
  background-repeat: no-repeat;
  border-radius: 25px;
  border: 2px solid transparent;
}
