@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: cairoFont;
  src: url("../public/Media/cairo_font/Cairo-VariableFont.ttf");
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

html {
  background-color: white;
}

body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.button-outline {
  @apply px-4 py-2 rounded-2xl border border-neutral-400 text-white font-bold;
  @apply transition duration-200 ease-in-out;

  &:hover {
    @apply bg-neutral-400 text-neutral-800;
  }
}

.custom-navlink {
  @apply text-white xl:text-base lg:text-xs hover:bg-white xl:hover:text-lg lg:hover:text-base hover:text-red-600 px-2 py-2 rounded-xl font-bold transition-all duration-200 ease-in-out;
}

.active {
  @apply bg-white text-red-600;
}

.signup-input {
  @apply flex justify-start border rounded-2xl w-full py-1.5 px-5 text-gray-700 mb-2 drop-shadow-xl;
}

.sign-button {
  @apply font-bold rounded-xl bg-black text-white py-1.5 px-10 hover:bg-white hover:text-black transition-all duration-200 ease-in-out drop-shadow-xl;
}

.circular-icon {
  @apply rounded-full border-2 border-black shadow-xl drop-shadow-xl w-32 sm:w-44 md:w-60 lg:w-72 hover:scale-110 transition duration-200 ease-in-out;
}

.circular-icon-text {
  @apply font-bold mt-3 lg:text-xl;
}

.choices-button {
  @apply px-8 py-1 rounded-2xl border border-gray-600 shadow-xl drop-shadow-xl font-medium hover:scale-110 transition-all duration-200 ease-in-out;
}

.dont-know-input {
  @apply w-[80%] rounded-2xl drop-shadow-2xl shadow-2xl border border-gray-600 py-2 px-3;
}

.dk-input-div {
  @apply flex flex-col items-center;
}

.dk-label {
  @apply block text-white mb-1 text-center font-medium text-lg;
}

.uni-div {
  @apply flex flex-col items-center lg:w-[20%] w-[90%] mt-10 cursor-pointer hover:-translate-y-6 transition duration-200 ease-in-out;
}

.uni-img {
  @apply h-96 w-full rounded-3xl p-2;
}

.uni-ico {
  @apply w-auto h-16;
}

.uni-div2 {
  @apply flex flex-col items-center md:w-[50%] mt-10 cursor-pointer hover:-translate-y-6 transition duration-200 ease-in-out;
}

.uni-img2 {
  @apply h-full w-[350px] rounded-xl object-cover;
}

.uni-link {
  @apply flex justify-center w-[60%] md:w-[50%] h-96 lg:w-full;
}
