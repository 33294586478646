.siginBody {
  position: relative;
  background-image: url("../../public/Media/SIGN_UP-IN.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
}

.siginBody::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: -1;
}
